<template>
  <!-- 反馈列表 -->
  <div>
    <div>
      <repPbcNav :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></repPbcNav>
    </div>
    <div>
      <repForm :pagination="pagination" @changePage="changePage" :isBtnShow="false" @disposeAdd="disposeAdd" v-model:columns="columns" :dataList="dataList" :reload="getFeedbackPage" :searchLoading="searchLoading"></repForm>
    </div>
    <div>
      <a-modal v-model:visible="disposeModalShow" title="处理反馈" @ok="submit" @cancel="feedbackValue=''">
        <div class="flex m-b1"><span style="color:red;margin-right:5px;">*</span>处理内容</div>
        <a-textarea class="textarea" v-model:value="feedbackValue" placeholder="请输入处理内容" :rows="6" />
    </a-modal>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import repPbcNav from '../components/repPbcNav.vue';
import repForm from '../components/repForm.vue';
import { IfNull } from '@/utils/util';
import { message } from 'ant-design-vue';
import { getOrgTreeList } from "@/api/global";
import {
  feedbackPage,feedbackDeal
} from "@/api/repository/repository";
const disposeModalShow = ref(false)
const searchLoading = ref(false);
const feedbackValue = ref('')
const page = ref(1)
const pagination = ref( {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (totals) => `共 ${totals} 条`
  })
const iptData = ref([
  {
    type: "select",
    placeholder: "适用部门",
    value: null,
    prop: "department",
    width: 200,
    opt: [],
  },
  {
    type: "input",
    placeholder: "反馈人员",
    value: null,
    prop: "feedbackName",
    width: 200,
    opt: [],
  },
  {
    type: "select",
    placeholder: "处理状态",
    value: null,
    prop: "feedbackState",
    width: 200,
    opt: [
      {
        id: 0,
        name: "未处理",
      },
      {
        id: 1,
        name: "已处理",
      },
    ],
  },]
)
const dataList = ref([]);//展示数据列表
const columns = ref([
  {
    title: '序号',
    slots: { customRender: 'sort' },
    align:'center',
  },
  {
    title: '反馈人员',
    dataIndex: 'operator',
    key: 'operator',
    align:'center',
  },
  {
    title: '所属部门',
    dataIndex: 'orgName',
    key: 'orgName',
    align:'center',
  },
  {
    title: '反馈内容',
    key: 'content',
    dataIndex: 'content',
    customHeaderCell: () => ({ style: { textAlign: "center" } }),
    width:'50%'
  },
  {
    title: '反馈类型',
    key: 'feedbackType',
    dataIndex: 'feedbackType',
    align:'center',
  },
  {
    title: '处理状态',
    key: 'disposeState',
    dataIndex: 'disposeState',
    align:'center',
    slots: { customRender: 'disposeState' },
  },
  {
    title: '反馈时间',
    key: 'createTime',
    dataIndex: 'createTime',
    align:'center',
  }
]);
//获取反馈分页列表
const getFeedbackPage = (msg = {}) => {
  searchLoading.value = true
  let primary = {
    orders: [
      {
        asc: false,
        column: "",
      },
    ],
    current: page.value,
    size: 10,
  }
  Object.assign(primary,msg)
  feedbackPage(primary).then((res) => {
    if(res.code !== 10000) return
    pagination.value.total = res.data.total
    res.data.records.forEach( opt => {
      opt.feedbackType = opt.isSys.value == 1?'系统':'人工' 
    })
    dataList.value = res.data.records;
    searchLoading.value = false
  });
};
//控制分页
const changePage = (val) => {
  page.value = val.current;
  getFeedbackPage(recordSearch.value);
  pagination.value = val;
};
//处理状态事件
const feedbackID = ref('')
const disposeAdd = (val) => {
  feedbackID.value = val.id
  disposeModalShow.value = true
}
//重置
const resetBtn = () => {
  page.value = 1
  pagination.value.current = 1
  recordSearch.value = {}
  getFeedbackPage()
}
//提交反馈内容
const submit = () => {
 if(!IfNull( feedbackValue.value)){
  message.warn('请输入处理内容')
   return
 }
 feedbackDeal({
   id:feedbackID.value,
   content:feedbackValue.value
 }).then(res => {
    if(res.code == 10000){
      message.success(res.msg)
      getFeedbackPage()
      disposeModalShow.value = false
      feedbackValue.value = ''
    }
 })
}
//搜索筛选事件
const recordSearch = ref({})
const searchAdd = (val) => {
  let msg = {
    isDeal:val.feedbackState,
    orgId:val.department,
    operator:val.feedbackName
  }
  recordSearch.value = msg
  page.value = 1;
  pagination.value.current = 1
  getFeedbackPage(msg)
};
// 获取部门
const getDepartment = () => {
  getOrgTreeList({}).then((res) => {
    if (res.code !== 10000) return;
    let treeList = res.data[0].treeList;
    iptData.value[0].opt = treeList;
  });
};

onMounted(() => {
  getFeedbackPage();
  getDepartment()
});
</script>

<style lang="less" scoped>
.textarea{
  resize: none;
}
</style>